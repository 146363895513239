<template>
  <div class="my">
    <div class="user-wp">
        <div class="user-box">
            <div class="user-top">
                <div class="user-top-fl">
                    <div class="avatar" :style="{'background-image':'url('+user.headimgurl+')'}"></div>
                    <div class="user-name">
                         <div>{{user.title}}</div>
                         <p>{{user.goalTitle.thisLv.title}} 成长值{{user.goal.goal}}/{{user.goalTitle.nextLv.start_point}}</p>
                    </div>
                </div>
                <span class="gome" @click="gome">个人中心 &gt;</span>
            </div>
            <div class="progressBar">
                <div class="bar"><span :style="{width:(( (user.goal.goal-user.goalTitle.thisLv.start_point) /(user.goalTitle.nextLv.start_point-user.goalTitle.thisLv.start_point) )*100)+'%'}"></span></div>
                <div class="bar_val">
                    <p>{{user.goalTitle.thisLv.title}}</p>
                    <p>{{user.goalTitle.nextLv.title}}</p>
                </div>
            </div>
           <div class="jf_num">
                <div class="num_li">
                    <img src="~@/assets/img/my-ico1.png">
                    <div>
                        <div>微贴</div>
                        <p>{{user.total.post}}</p>
                    </div>
                </div>
                <div class="num_li" @click="integral">
                    <img src="~@/assets/img/my-ico2.png">
                    <div>
                        <div>积分</div>
                        <p>{{user.goal.goal}}</p>
                    </div>
                </div>
           </div>
            
        </div>
     </div>
    <div class="btns_wrap">
        <div class="btn_tit">我的</div>
        <div class="btns">
            <div class="btn1" @click="btn1"><img src="~@/assets/img/my-btn1.png">评论</div>
            <div class="btn2" @click="btn2"><img src="~@/assets/img/my-btn2.png">点赞</div>
            <div class="btn3" @click="btn3"><img src="~@/assets/img/my-btn3.png">收藏</div>
            <div class="btn4" @click="btn4"><img src="~@/assets/img/my-btn4.png">关注</div>
        </div>
    </div>
    <div class="setUp">
        <div class="setUp_tit">设置</div>
        <ul>
            <li @click="seting">
                <div class="li_con"><img src="~@/assets/img/my-ico3.png">基础设置</div>
                <div class="my_jt"></div>
            </li>
            <li @click="complaint">
                <div class="li_con"><img src="~@/assets/img/my-ico4.png">投诉</div>
                <div class="my_jt"></div>
            </li>
            <li @click="contactKf">
                <div class="li_con"><img src="~@/assets/img/my-ico5.png">联系客服</div>
                <div class="my_jt"></div>
            </li>
        </ul>
    </div>
    <!-- <br><br><br><br><br>    <div style="text-align: center;">version:0.034</div> -->
    <Navbar nav="my" />
    <!-- 联系客服弹窗 -->
    <div class="contactMask" v-show="isKf">
        <div class="contact_wrap">
            <div class="contact_tit">客服电话</div>
            <div class="tel">0116-0188440</div>
            <p>很抱歉，给您带来不便</p>
            <div class="callBtn" @click="callPhone('0116-0188440')">立即拨打</div>
            <div class="cancel" @click="cancel_btn">取消</div>
        </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Api from '@/api'
import Vue from 'vue'
export default {
  name: 'My',
  data(){
    return {
      title:'loading...',
      user:{},
      isKf:false, //联系客服弹窗
    }
  },
  components: {
    Navbar
  },
  methods:{
    gome(){
      this.$router.push('/user/'+this.user.id);
    },
    integral(){this.$router.push('/Integral');},
    btn1(){this.$router.push('/my_comment');},
    btn2(){this.$router.push('/my_like');},
    btn3(){this.$router.push('/my_fav');},
    btn4(){this.$router.push('/my_follow');},
    seting(){this.$router.push('/Seting');},
    complaint(){this.$router.push('/Complaint/0');},
    contactKf(){
        this.isKf = true
    },
    //打电话
    callPhone(phoneNumber){
        window.location.href = 'tel://' + phoneNumber
    },
    cancel_btn(){
        this.isKf = false
    }
  },
  mounted(){
    var that=this
    Api.go('user',{id:0},(res)=>{
      Vue.set(that,'user',res)
    })
  }
}
</script>

<style lang="less" scoped>
.user-wp{
    padding:.2rem .32rem;
    .user-box{
        height: 3.76rem;
        background:url(~@/assets/img/my-head.png) no-repeat center top/100% 3.76rem;
        padding: .24rem;
        box-sizing: border-box;
    }
    .user-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-top-fl{
            display: flex;
            align-items: center;
        }
        .avatar{
            width: .96rem;
            height: .96rem;
            border-radius:50%;
            background-size: cover;
            margin-right: .2rem;
        }
        .user-name{
            div{
                font-size: .32rem;
                font-weight: bold;
                color: #131415;
            }
            p{
                font-size: .24rem;
                color: #666;
            }
        }
        .gome{
            font-size: .24rem;
            color: #666;
        }
    }
    .progressBar{
        margin: .32rem auto .36rem;
        .bar{
            width: 6.22rem;
            height: .06rem;
            background:rgba(255, 255, 255, .5);
            border-radius: .03rem;
            position: relative;
            span{
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                background: #F65102;
                border-radius: .03rem;
            }
        }
        .bar_val{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: .16rem;
            p{
                width: .74rem;
                height: .34rem;
                line-height: .34rem;
                background:rgba(255, 255, 255, .6);
                border-radius: .18rem;
                font-size: .24rem;
                font-weight: bold;
                color: #F9B188;
                text-align: center;
                &:first-child{
                    color: #F65102;
                }
            }
        }
    }
    .jf_num{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .num_li{
            display: flex;
            align-items: center;
            img{
                max-width: .48rem;
                margin-right: .16rem;
            }
            div{
                font-size: .28rem;
                font-weight: 400;
                color: #666666;         
            }
            p{
                color: #333;
            }
        }
    } 
}
.btns_wrap{
    height: 3.1rem;
    background:url(~@/assets/img/my-wrap.png) no-repeat center top/100% auto;
    margin-top: -.8rem;
    padding: .5rem .32rem .32rem;
    box-sizing: border-box;
    .btn_tit{
        font-size: .32rem;
        font-weight: bold;
        color: #333;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        padding-bottom: .22rem;
    }
    .btns{
        display: flex;
        justify-content: space-around;
        padding-top:.32rem;
        div{
            font-size: .28rem;
            color: #777;
            text-align: center;
            width: .8rem;
            img{
                width: .8rem;
                height: .8rem;
            }
        }
    }
}   
.setUp{
    margin: .32rem;
    background: #fff;
    padding:0 .32rem .32rem;
    border-radius: .16rem;
    .setUp_tit{
        font-size: .32rem;
        color: #333;
        font-weight:bold;
        padding: .22rem;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    ul{
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: .4rem;
            .li_con{
                font-size: .28rem;
                color: #333;
                img{
                    width: .32rem;
                    height: .32rem;
                    margin-right: .16rem;
                }
            }
            .my_jt{
                width: .28rem;
                height: .28rem;
                background: url(~@/assets/img/my-jt.png) no-repeat center top/100% auto;
            }
            

        }
    }
}
.contactMask{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .contact_wrap{
        width: 4.78rem;
        border-radius: .24rem;
        background: #fff;
        text-align: center;
        .contact_tit{
            font-size: .32rem;
            color: #333;
            padding: .32rem 0;
        }
        .tel{
            font-size: .36rem;
            font-weight: bold;
            color: #333;
            margin-bottom: .32rem;
        }
        p{
            font-size: .28rem;
            color: #999;
        }
        .callBtn{
            width: 3.32rem;
            height: .76rem;
            line-height: .76rem;
            background: #F65102;
            border-radius: .38rem;
            font-size: .28rem;
            color: #fff;
            text-align: center;
            margin: .6rem auto 0;
        }
        .cancel{
            font-size: .28rem;
            color: #F65102;
            padding:.4rem;
        }
    }
}
</style>